<template>
   <v-row justify="space-between">
      <v-col cols="12" class="text-h4 my-6">
         {{node.name}}
      </v-col>
      <v-col cols="12" md="7">
         <v-card width="160" color="#eef" class="mb-5" v-for="(d,i) in node.devices.filter(x => x.type == 'display')" :key="i">
            <v-card-text >
               <v-icon color="primary" size="30">{{d.icon}}</v-icon>
               <label for="">{{d.name}}</label>
               <div class="d-flex justify-center mt-2">
                  <h2 class="text-h2">{{ $store.state[d.id] }}</h2>
                  <h6 class="text-h6">{{d.unit}}</h6>
               </div>
            </v-card-text>
         </v-card>
         <div class="text-h5 my-3">Temperature</div>
         <div style="height:300">
            <chart :data="chartCfg"/>
         </div>
      </v-col>
      <v-col cols="12" md="4">
         <v-card v-for="(d,i) in node.devices.filter(x => x.type != 'display')" :key="i" class="mb-4">
            <v-card-text>
               <v-row class="mx-0" justify="space-around">
                  <v-col cols="12" md="7" class="text-center">
                     <v-icon color="primary" size="100">{{d.icon}}</v-icon>
                     <h6 class="text-h6">{{d.name}}</h6>
                     <h2 class="text-h2">{{$store.state[d.dev][d.id]}}</h2>
                  </v-col>
                  <v-col cols="12" md="5">
                     <v-slider vertical :value="$store.state[d.dev][d.id]" @input="upd(d.dev, d.id, arguments[0])" max="255"/>
                  </v-col>
               </v-row>
            </v-card-text>
         </v-card>
      </v-col>
   </v-row>
</template>

<script>
import Chart from '../components/Chart'
import { nodes } from '../../config'

export default {
   props: {
      node: Object,
   },
   components: { Chart },
   data: () => ({
      chartCfg: {
         datasets: [{ }],
         labels: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
      },
      name: '-',
   }),
   computed: {
      suhu2() {
         return this.$store.state.suhu2;
      },
      kt2: {
         get() { return this.$store.state.node.kt2 },
         set(val) { this.upd('kt2', val) }
      },
      cf3: {
         get() { return this.$store.state.cf3 },
         set(val) { this.upd('cf3', val) }
      }
   },
   watch: {
      node(val) {
         for(let i in nodes[val]) {
            this[i] = nodes[val][i];
         }
      }
   },
   methods: {
      upd(node, key, val) {
         clearTimeout(this.timer);
         this.timer = setTimeout(() => {
            this.$store.dispatch(`${node}/set`, {[key]: val})
         }, 300);
      }
   }
}
</script>